import React from "react";
import cx from "classnames";
import styles from "components/ui/select/SelectMenu.module.scss";
import { ToggleLayer, Transition } from "react-laag";

interface ISelectMenu {
  name: string;
  children: React.ReactNode;
  className?: string;
  isOpened: boolean;
  toggle: () => void;
  offset?: number;
  width?: "parent" | "fit-content" | number;
}

const SelectMenu = React.memo(
  ({
    children,
    className,
    isOpened,
    name,
    toggle,
    offset = 8,
    width = "parent",
  }: ISelectMenu) => (
    <ToggleLayer
      isOpen={isOpened}
      placement={{
        anchor: "BOTTOM_CENTER",
        autoAdjust: true,
        triggerOffset: offset,
        possibleAnchors: [
          "BOTTOM_CENTER",
          // "LEFT_CENTER",
          // "RIGHT_CENTER",
          "TOP_CENTER",
        ],
      }}
      onOutsideClick={toggle}
      renderLayer={({ isOpen, layerProps, triggerRect }) => (
        <Transition isOpen={isOpen}>
          {(isOpen, onTransitionEnd) => {
            console.log(name, isOpen, isOpened);
            const styleWidth =
              width === "parent"
                ? triggerRect?.width
                : typeof width === "number"
                ? width
                : "fit-content";
            let style = {
              ...layerProps.style,
              opacity: isOpen ? 1 : 0,
              width: styleWidth,
            } as Record<string, any>;
            return (
              <div
                className={cx(className, styles.menu, isOpen && styles.open)}
                {...layerProps}
                style={style}
                onTransitionEnd={onTransitionEnd}
              >
                <ul
                  id={`select-${name}-results`}
                  role="listbox"
                  aria-hidden={!isOpened}
                  aria-expanded={isOpened}
                >
                  {children}
                </ul>
              </div>
            );
          }}
        </Transition>
      )}
    >
      {({ triggerRef }) => (
        <div className={styles.menuTrigger} ref={triggerRef} onClick={toggle} />
      )}
    </ToggleLayer>
  )
);

export default SelectMenu;
