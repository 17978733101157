import React from "react";
import createIcon from "./createIcon";

const IconFastForward= createIcon(
  <React.Fragment>
      <path d="M12 7.41L16.58 12L12 16.59L13.41 18L19.41 12L13.41 6L12 7.41Z" fill="currentColor" />
      <path d="M5 7.41L9.58 12L5 16.59L6.41 18L12.41 12L6.41 6L5 7.41Z" fill="currentColor"/>
   </React.Fragment>,
  "FastForward"
);

export default IconFastForward;
