import React from "react";
import styles from "./Pagination.module.scss";
import IconExpandArrow from "components/ui/icons/IconExpandArrow";
import IconFastForward from "components/ui/icons/IconFastForward";
import Select from "components/ui/select/Select";
import { SelectOption } from "components/ui/select/SelectOption"

interface Props {
  limit: number;
  current: number;
  total: number;
  service: {
    prevPage: () => void;
    nextPage: () => void;
    firstPage: () => void;
    lastPage: () => void;
    updatePageLimit: (limit: number) => void;
  };
}
const Pagination = ({ limit, current, total, service }: Props) => {
  return (
    <div className={styles.root}>
      <PageLimits limit={limit} service={service} />
      <div className={styles.pageContainer}>
        <button
          className={styles.first}
          onClick={() => service.firstPage()}
          disabled={current === 0}
        >
          <IconFastForward />
        </button>
        <div className={styles.pages}>
          <button
            className={styles.prev}
            onClick={() => service.prevPage()}
            disabled={current === 0}
          >
            <IconExpandArrow />
          </button>
          <span>
            {current + 1} of {total}
          </span>
          <button
            className={styles.next}
            onClick={() => service.nextPage()}
            disabled={current === total - 1}
          >
            <IconExpandArrow />
          </button>
        </div>
        <button
          className={styles.last}
          onClick={() => service.lastPage()}
          disabled={current === total - 1}
        >
          <IconFastForward />
        </button>
      </div>
    </div>
  );
};

const PageLimits = ({ limit, service }: Pick<Props, "limit" | "service">) => {
  const onClick = (name: string, value: string) =>
    service.updatePageLimit(+value);
  return (
      <Select
        label={"Page Size:"}
        name={"limit"}
        onSelect={onClick}
        value={`${limit}`}
        // className={styles.limit}
        inputClassName={styles.limit}
        menuClassName={styles.limitMenu}
        noInputError
        chip
      >
        <SelectOption value="5" />
        <SelectOption value="10" />
        <SelectOption value="25" />
        <SelectOption value="50" />
      </Select>
  );
};

export default Pagination;
