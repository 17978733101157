import React from "react";
import cx from "classnames";
import styles from "components/ui/spell/SpellList.module.scss";
import { Link } from "gatsby";
import {
  defaultSpellFields, getSpellLevelProperty,
  getSpellSchool,
  ISpell,
} from "models/Spell"
import Chip from "components/ui/chip/Chip";
import {
  SpellCasting,
  SpellDamage,
  SpellLevel,
  SpellMaterials,
  SpellRange,
  SpellSpecialCharacteristics,
  SpellType,
} from "components/ui/spell/SpellComponents";
import IconBrew from "components/ui/icons/IconBrew";
import { useSelector } from "react-redux";
import { IState } from "store";
import { AnimatePresence, motion } from "framer-motion";

interface Props {
  list: ISpell[];
  children?: React.ReactNode;
  className?: string;
}

const SpellList = ({ list, children }: Props) => {
  const isMobile = useSelector((s: IState) => s.app.layout.isMobileLayout);
  return (
    <>
      <div>
        <Headers />
        <AnimatePresence initial={false}>
          {list.map((s) => (
            <SpellRow spell={s} key={s.name} expanded={isMobile} />
          ))}
        </AnimatePresence>
      </div>
      {children}
    </>
  );
};

interface ISpellRow {
  spell: ISpell;
  expanded: boolean;
}

const SpellRow = ({ spell, expanded }: ISpellRow) => {
  const {
    level,
    actionType,
    school,
    save,
    activation,
    components,
    materials,
    range,
    target,
  } = spell.data;
  const { classes, damage, homebrew } = spell.fields || defaultSpellFields;

  return (
    <Link to={spell.fields?.slug || "/"}>
      <motion.div
        layoutId={spell.name}
        className={styles.row}
        exit={{ opacity: 0, y:-20 }}
        // initial={{ opacity: 0, }}
        // animate={{ opacity: 1,  }}
      >
        {homebrew && <IconBrew className={styles.brew} />}
        <div className={cx(styles.firstCols)}>
          <SpellLevel level={level} className={styles.runeCol} />
          <div className={cx(styles.headerCol, styles.left)}>
            <div className={styles.spellHeader}>{spell.name}</div>
            <Chip className={styles.chip}>
              {getSpellSchool(school)} - {getSpellLevelProperty(level, 'label')}
            </Chip>
          </div>
        </div>
        <div className={styles.otherCols}>
          <div className={cx(styles.horizontal, styles.colSmallFixed)}>
            <SpellSpecialCharacteristics
              components={components}
              condensed={!expanded}
            />
            <SpellMaterials materials={materials} condensed={!expanded} />
          </div>
          <div className={styles.col3}>
            <SpellCasting activation={activation} condensed={!expanded} />
          </div>
          <div className={styles.col3}>
            <SpellType
              actionType={actionType}
              save={save}
              condensed={!expanded}
            />
          </div>
          <div className={styles.col3}>
            <SpellRange range={range} target={target} condensed={!expanded} />
          </div>
          <div className={styles.colSmallFixed}>
            <SpellDamage damage={damage} condensed={!expanded} />
          </div>
        </div>
      </motion.div>
    </Link>
  );
};

const Headers = () => (
  <div className={styles.header}>
    <div className={styles.runeCol} />
    <div className={cx(styles.col3, styles.left)}>Spell Name</div>
    <div className={styles.colSmallFixed} />
    <div className={styles.col}>Time</div>
    <div className={styles.col}>Type</div>
    <div className={styles.col}>Range/Effect</div>
    <div className={styles.colSmallFixed}>Damage</div>
  </div>
);

export default SpellList;
