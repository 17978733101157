import { appGetState, appNextState, appUpdateState } from "store";
import { DataFilterService } from "services/DataFilterService";

export interface TBreadcrumb {
  label: string;
  url?: string;
}

const spellsService = new DataFilterService(
  "spells",
  appGetState,
  appUpdateState
);
export { spellsService };
