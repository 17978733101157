import React from "react";
import { ClassIcon } from "components/ui/icons/ClassIcons";

const classList = [
  { key: "Artificer" },
  { key: "Barbarian" },
  { key: "Bard" },
  { key: "Cleric" },
  { key: "Druid" },
  { key: "Fighter" },
  { key: "Monk" },
  { key: "Paladin" },
  { key: "Ranger" },
  { key: "Rogue" },
  { key: "Sorcerer" },
  { key: "Warlock" },
  { key: "Wizard" },
  { key: "Spellblade" },
  { key: "Soulbinder" },
  { key: "Psion" },
];

export const classDimensionOptions = classList.map((c) => {
  const key = c.key;
  return {
    key,
    icon: <ClassIcon classIconName={key} />,
  };
});
