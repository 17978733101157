import cx from "classnames";
import styles from "components/ui/select/SelectOption.module.scss";
import React from "react";
import { useSelectOptionState } from "components/ui/select/SelectWrapper"

export interface IOption {
  value: string;
  label?: string;
  className?: string;
  children?: React.ReactNode;
}
export const SelectOption = ({
  value,
  label,
  className,
  children,
}: IOption) => {
  const {
    name,
    isActive,
    isHighlighted,
    onClick,
    onHover,
  } = useSelectOptionState(value);
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <li
      className={cx(
        className,
        styles.option,
        isActive && styles.active,
        isHighlighted && styles.highlighted
      )}
      id={`select-${name}-result-${value}`}
      role="option"
      aria-selected={isActive}
      onClick={onClick}
      onMouseOver={onHover}
      onFocus={onHover}
    >
      {children || label || value}
    </li>
  );
};
