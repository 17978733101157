import React from "react";
import cx from "classnames";
import styles from "components/ui/autocomplete/ActiveOptionChip.module.scss";
import Chip from "components/ui/chip/Chip";
import { IFacetOption } from "models/Dimension";
import IconCancel from "components/ui/icons/IconCancel";

interface Props extends IFacetOption {
  onClick?: (key: string) => void;
  id: string;
  className?: string;
  noCancelIcon?: boolean;
}

const ActiveOptionChip = React.memo(
  ({ color, onClick, className, noCancelIcon = false, ...props }: Props) => {
    const styleProps = color
      ? {
          style: { color, background: color + 28 },
          iconStyle: { color, background: color + 28 },
        }
      : {};
    const clickProps = onClick ? { onClick: () => onClick(props.id) } : {};
    return (
      <Chip
        {...props}
        {...styleProps}
        className={cx(styles.root, className, noCancelIcon && styles.noCancel)}
        theme="primary"
        condensed
        {...clickProps}
      >
        {props.label || props.id}{" "}
        {!noCancelIcon && <IconCancel className={styles.cancel} />}
      </Chip>
    );
  }
);

export default ActiveOptionChip;
