import { createBitIndices } from "utils/bitUtils";
import {
  FACET_MATCH_ARRAY_DIM,
  FACET_MATCH_DIM,
  FUZZY_MATCH_DIM,
  IDimension,
} from "models/Dimension";
import { classDimensionOptions } from "models/CharacterClass";
import { damageTypes, spellLevel, spellSchools } from "models/Spell";

const booleanOptions = [
  {
    key: "true",
  },
  {
    key: "false",
  },
];

const filters = [
  {
    key: "name",
    label: "Spell Name",
    match: FUZZY_MATCH_DIM,
  },

  {
    key: "fields.damage",
    label: "Damage Type",
    match: FACET_MATCH_ARRAY_DIM,
    options: damageTypes,
  },
  {
    key: "fields.classes",
    label: "Class",
    match: FACET_MATCH_ARRAY_DIM,
    options: classDimensionOptions,
  },
  {
    key: "data.school",
    label: "School",
    match: FACET_MATCH_DIM,
    options: spellSchools,
  },
  {
    key: "data.level",
    label: "Spell Level",
    match: FACET_MATCH_DIM,
    options: spellLevel,
  },
  {
    key: "data.activation.type",
    label: "Casting Time",
    match: FACET_MATCH_ARRAY_DIM,
  },
  {
    key: "data.components.ritual",
    label: "Ritual",
    options: booleanOptions,
  },
  {
    key: "data.components.concentration",
    label: "Concentration",
    options: booleanOptions,
  },
] as IDimension[];

export const getDimension = (dim: string) => {
  return filters.find((f) => f.key === dim);
};

export const getDimensionOptions = (dim: string) => {
  return filters.find((f) => f.key === dim)?.options;
};

//remove options from array
const cleanedFilters = filters.map(({ options, ...rest }) => rest);

export const spellDimensions = createBitIndices(cleanedFilters);
