import React, { useRef, useState } from "react";
import cx from "classnames";
import InputFeedback, {
  TError,
} from "components/ui/form-elements/InputFeedback";
import IconExpandArrow from "components/ui/icons/IconExpandArrow";
import styles from "./Select.module.scss";
import inputStyles from "components/ui/text-input/TextField.module.scss";
import SelectWrapper from "components/ui/select/SelectWrapper";
import SelectMenu from "components/ui/select/SelectMenu";
import ActiveOptionChip from "components/ui/autocomplete/ActiveOptionChip";
import { IFacetOption } from "models/Dimension";
import { SelectOption } from "components/ui/select/SelectOption";
import Chip from "components/ui/chip/Chip";

export interface ISelectProps {
  name: string;
  value: string | string[] | null;
  onSelect: (
    name: string,
    value: any,
    config?: { shouldValidate?: boolean; shouldDirty?: boolean }
  ) => void;
  label: string;
  description?: string;
  multiSelect?: boolean;
  options?: IFacetOption[];
  className?: string;
  inputClassName?: string;
  menuClassName?: string;
  disabled?: boolean;
  placeholder?: string;
  error?: TError;
  defaultValue?: string;
  noInputError?: boolean;
  children?: React.ReactNode;
  menuWidth?: "fit-content" | "parent" | number;
  menuOffset?: number;
  chip?: boolean;
  // [key: string]: any;
}

const Select = React.forwardRef(
  (
    {
      name,
      value,
      onSelect,
      label,
      description,
      multiSelect,
      options,
      className,
      inputClassName,
      menuClassName,
      disabled,
      placeholder = "Select an option",
      error,
      defaultValue: defV,
      children,
      noInputError = false,
      menuWidth,
      menuOffset,
      chip = false,
      ...rest
    }: ISelectProps,
    ref: React.Ref<any>
  ) => {
    const [state, setStateFun] = useState({
      isOpened: false,
      isFocused: false,
    });
    const [highlight, setHighlight] = useState<number | string>(-1);
    const wrapperElementRef = useRef<HTMLDivElement>(null);
    const selected = value || "";
    const selectedOptions = options
      ? options.filter((d) => selected.includes(d.key))
      : Array.isArray(selected)
      ? selected
      : [];
    const props = defV
      ? {
          defaultValue: defV,
        }
      : {};

    const setState = (newState: Record<string, any>) => {
      setStateFun({ ...state, ...newState });
    };
    const onSelectionBlur = () => {
      setState({
        isFocused: false,
      });
    };

    const onSelectionClick = () => {
      setHighlight(-1);
      setState({
        isOpened: !state.isOpened,
        isFocused: !state.isFocused,
      });
    };
    const onSelectionFocus = () => {
      setState({
        isFocused: true,
      });
    };

    const ariaProps = {
      role: "combobox",
      "aria-controls": `select-${name}-results`,
      "aria-expanded": state.isOpened,
      "aria-selected": state.isFocused,
      "aria-haspopup": true,
      "aria-owns": `select-${name}-results`,
      "aria-activedescendant":
        state.isOpened && highlight !== -1
          ? `select-${name}-result-${highlight}`
          : undefined,
    };
    const labelEle = (
      <label
        htmlFor={`select-input-${name}`}
        className={inputStyles.label}
        id={`select-input-label-${name}`}
      >
        {label}
        {label && description && <br />}
        {description && <span>{description}</span>}
      </label>
    );

    return (
      <>
        <SelectWrapper
          name={name}
          selected={selected}
          onSelect={onSelect}
          isFocused={state.isFocused}
          isOpened={state.isOpened}
          highlight={highlight}
          setHighlight={setHighlight}
          setState={setState}
          multiSelect={multiSelect}
          wrapRef={wrapperElementRef}
        >
          <div
            className={cx(
              className,
              styles.root,
              error && inputStyles.error,
              chip && styles.chip,
              multiSelect && styles.multiSelect
            )}
            {...ariaProps}
            ref={wrapperElementRef}
          >
            {!chip && labelEle}

            <div
              className={cx(
                inputClassName,
                styles.inputWrapper,
                disabled && styles.disabled,
                (state.isFocused || state.isOpened) && styles.focus,
              )}
            >
              <SelectMenu
                name={name}
                isOpened={state.isOpened}
                className={menuClassName}
                width={menuWidth}
                toggle={onSelectionClick}
                offset={menuOffset}
              >
                {children}
                {options &&
                  options.map((d) => (
                    <SelectOption key={d.key} value={d.key}>
                      {value?.includes(d.key) ? (
                        <ActiveOptionChip
                          id={d.key}
                          {...d}
                          noCancelIcon={!multiSelect}
                        />
                      ) : (
                        <Chip icon={d.icon} condensed variant={"empty"}>
                          {d.label || d.key}
                        </Chip>
                      )}
                    </SelectOption>
                  ))}
              </SelectMenu>
              {chip && labelEle}
              {multiSelect && (
                <ActiveOptions
                  name={name}
                  options={selectedOptions}
                  onSelect={onSelect}
                />
              )}
              {chip && selected.length === 0 && (
                <div className={styles.placeholder}>{placeholder}</div>
              )}
              <input
                id={`select-input-${name}`}
                type="text"
                readOnly
                name={name}
                value={selected}
                disabled={disabled}
                className={cx(styles.input)}
                ref={ref}
                placeholder={!chip ? placeholder : ""}
                aria-labelledby={`select-input-label-${name}`}
                aria-describedby={error && `error-${name}-${error?.type}`}
                aria-invalid={!!error}
                tabIndex={disabled ? -1 : undefined}
                {...props}
                {...rest}
                // onClick={onSelectionClick}
                onBlur={onSelectionBlur}
                onFocus={onSelectionFocus}
              />
              <IconExpandArrow className={styles.expand} />
            </div>
          </div>
        </SelectWrapper>

        {!noInputError && (
          <InputFeedback error={error} visible={!!error} name={name} />
        )}
      </>
    );
  }
);

const ActiveOptions = ({
  options,
  name,
  onSelect,
}: {
  options: (string | IFacetOption)[];
  name: string;
  onSelect: (name: string, value: string) => void;
}) => {
  return (
    <div className={styles.activeChipContainer}>
      {options.map((d) => {
        const s = typeof d === "string" ? { key: d } : d;
        return (
          <ActiveOptionChip
            className={styles.activeChip}
            onClick={() => onSelect(name, s.key)}
            id={s.key}
            {...s}
          />
        );
      })}
    </div>
  );
};

export default Select;
