import React from "react";
import { DebounceInput } from "react-debounce-input";
import TextInput from "components/ui/text-input/TextField";
import styles from "components/ui/spell/SpellFilters.module.scss";
import { IDimension } from "models/Dimension";
import { getDimension } from "models/SpellDimensions";
import Select from "components/ui/select/Select";

interface Props {
  children?: React.ReactNode;
  dimensions: Record<string, IDimension>;
  onFilter: (key: string, bucket: string | null) => void;
  className?: string;
}

const SpellFilters = ({ children, className, dimensions, onFilter }: Props) => {
  const nameValue =
    typeof dimensions?.name?.buckets === "string"
      ? dimensions.name.buckets
      : "";

  const filters = ["data.level", "fields.classes", "fields.damage","data.school", "data.components.concentration"];
  return (
    <>
      <DebounceInput
        name={"name"}
        value={nameValue}
        onChange={(e) => onFilter("name", e.target.value)}
        debounceTimeout={300}
        element={TextInput}
        label={"Search by Spell Name"}
      />
      {/*<ActiveSpellFilters dimensions={dimensions} onFilter={onFilter} />*/}
      <div className={styles.content}>
        {filters.map((f) => {
          const dim = getDimension(f);
          return (
            <Select
              key={f}
              name={f}
              label={dim?.label || ""}
              options={dim?.options}
              onSelect={onFilter}
              multiSelect={true}
              value={dimensions?.[f].buckets}
              placeholder="Add filter"
              noInputError
              chip
            />
          );
        })}
      </div>
    </>
  );
};

export default SpellFilters;
