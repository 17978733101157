import React, { useEffect } from "react";
import SEO from "components/seo";
import { graphql, navigate } from "gatsby";
import queryString from "query-string";
import SpellList from "components/ui/spell/SpellList";
import { ISpell } from "models/Spell";
import { useOnMount } from "components/hooks/useOnMount";
import { useSelector } from "react-redux";
import { IState } from "store";
import AppContentContainer from "components/layouts/appLayout/AppContentContainer";
import SpellFilters from "components/ui/spell/SpellFilters";
import { spellsService } from "services/SpellsService";
import { spellDimensions as initialDimensions } from "models/SpellDimensions";
import { diffParamsAndDimensions, dimensionsToParamsFormat } from "utils/filterUtils"
import Pagination from "components/ui/spell/Pagination"

interface Props {
  data: {
    list: {
      edges: { node: ISpell }[];
    };
  };
  children?: React.ReactNode;
  match: { params: { id: string } };
  location: { hash: string; pathname: string; search: string };
  history: History;
}

const updateParams = (params: any, replace?: boolean) => {
  const prms = queryString.stringify(params, { arrayFormat: "comma" });
  const query = prms.length > 0 ? `?${prms.toString()}` : "";
  navigate(`${location.pathname}${query}`, { replace });
};

const SpellListPage = ({ data, location: { search } }: Props) => {
  const spells = useSelector((s: IState) => s.app.spells.active);
  const page = useSelector((s: IState) => s.app.spells.page);
  const dimensions = useSelector((s: IState) => s.app.spells.dimensions);
  const dimParam = dimensionsToParamsFormat(dimensions);

  useOnMount(() => {
    spellsService.reset(
      data.list.edges.map((e) => e.node),
      initialDimensions
    );
  });

  const onFilter = async (key: string, value: string | null) => {
    console.log("onFilter", key, value);
    const v = value ? value : null;
    const newDim = await spellsService.updateFilter({
      key: key,
      bucket: v,
    });
    //update params on filter change
    updateParams(dimensionsToParamsFormat(newDim));
  };

  //when hash changes, check for a diff between hash params and state (basically for redirect)
  useEffect(() => {
    const params = queryString.parse(search, { arrayFormat: "comma" });
    const diff = diffParamsAndDimensions(dimParam, params);
    if (diff.length > 0) {
      if (Object.keys(params).length > 0) {
        spellsService.bulkUpdateFilters(diff)
      } else {
        updateParams(dimParam, true);
      }
    }
  }, [search]);

  return (
    <>
      <SEO title="Spells" />
      <AppContentContainer spacing="extra">
        <h1>Spells</h1>
        {dimensions?.name && (
          <SpellFilters dimensions={dimensions} onFilter={onFilter} />
        )}
        <br />
        <SpellList list={spells} />
        <Pagination service={spellsService} {...page} />
        <br />
        <br />
        <br />
        <br />
        <br />


      </AppContentContainer>
    </>
  );
};
export const pageQuery = graphql`
  query {
    list: allSpellsJson(sort: { order: ASC, fields: [data___level, name] }) {
      edges {
        node {
          name
          data {
            level
            actionType
            school
            save {
              ability
            }
            activation {
              cost
              type
              condition
            }
            duration {
              value
              units
            }
            target {
              value
              units
              type
            }
            range {
              value
              units
            }
            components {
              vocal
              somatic
              material
              ritual
              concentration
            }
            materials {
              consumed
              cost
            }
          }
          fields {
            slug
            classes
            damage
            homebrew
          }
        }
      }
    }
  }
`;
export default SpellListPage;
